.app {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.document {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 720px) {
    margin-top: -1rem;
  }
}

.navBar {
  width: 100vw;

  position: absolute;
  bottom: 0;

  padding: 1rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #181818;

  p {
    margin: 0 1rem;
    color: #fff;
  }

  button {
    width: 7rem;
    padding: 3px 0;
    border: none;
    border-radius: 5px;
    background-color: #fff;
  }
}

.loading {
  padding: 0;
  margin: 0;
  position: absolute;

  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 90vh;

  z-index: 2;
}